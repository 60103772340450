import React, {useEffect, useState} from "react";
import Gamepad from "./Gamepad";

function pollGamepads() {
    if (navigator.getGamepads) {
        return navigator.getGamepads();
    } else {
        return [];
    }
}
export default function GamepadContainerWithoutContext() {
    const [gamepads, setGamepads] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        let done = false;

        function tick() {
            if (done) return;
            setGamepads(pollGamepads());
            setRefresh((r) => !r);
            window.requestAnimationFrame(() => tick());
        }

        tick();

        return () => {
            done = true;
        };
    }, []);

    return (
        <div className="container d-flex flex-column">
            <div className="mt-2">
                {[].slice.call(gamepads).map((gamepad, key) =>
                    <Gamepad key={key} refresh={refresh} gamepad={gamepad}/>)}
            </div>
        </div>
    );
}
