import React, {useContext, useEffect, useState} from "react";
import Gamepad from "./Gamepad";
import {ClipLoader} from "react-spinners";
import {GamepadsContext} from "react-gamepads";

export default function GamepadContainerWithContext() {
    const {gamepads} = useContext(GamepadsContext);
    const [gamepad, setGamepad] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        setGamepad(null);
        setIsConnected(false);
        Object.keys(gamepads).forEach((key) => {
            if (gamepads[key] !== null) {
                setGamepad(gamepads[key]);
                setIsConnected(true);
            }
        })
    }, [gamepads]);

    return (
        <div className="container d-flex flex-column">
            <div className="mt-2">
                {!isConnected && 'Press gamepad buttons to wake it up'}
            </div>
            <div className="mt-2">
                {isConnected
                    ? <Gamepad gamepad={gamepad}/>
                    : <ClipLoader color="#00000" loading={true} size={150}/>}
            </div>
        </div>
    );
}
