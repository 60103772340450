/*eslint no-mixed-operators: "error"*/

import React, {useEffect, useState} from 'react';
import Cursor from "./Cursor";
import {Button} from "react-bootstrap";
import Serial from "./Serial";

const MULTIPLIER = 5;
const DEAD_ZONE = 0.01;

const Gamepad = ({gamepad, refresh}) => {
    const [top, setTop] = useState(220);
    const [left, setLeft] = useState(220);
    const [pressed, setPressed] = useState(false);

    useEffect(() => {
        if (!gamepad) {
            return;
        }

        if ('buttons' in gamepad) {
            if (gamepad.buttons[7]) {
                gamepad.buttons[7].pressed ? setPressed(true) : setPressed(false);
            }
        }

        if ('axes' in gamepad) {
            if (gamepad.axes[1] > DEAD_ZONE || gamepad.axes[1] < -1 * DEAD_ZONE) {
                setTop((prevTop) => prevTop + (gamepad.axes[1] * MULTIPLIER));
            }

            if (gamepad.axes[0] > DEAD_ZONE || gamepad.axes[0] < -1 * DEAD_ZONE) {
                setLeft((prevTop) => prevTop + (gamepad.axes[0] * MULTIPLIER));
            }
        }
    }, [gamepad, refresh]);

    useEffect(() => {
        if (top >= 440) {
            setTop(440);
        }

        if (top <= 0) {
            setTop(0);
        }

        if (left >= 440) {
            setLeft(440);
        }

        if (left <= 0) {
            setLeft(0);
        }
    }, [top, left]);

    return gamepad ? (
        <div>
            <h3>{gamepad.id}</h3>
            <div className="d-flex mt-4">
                <div className="col-6 justify-content-center d-flex">
                    <div className="position-relative" style={{width: 500, height: 500, border: '5px solid black'}}>
                        <Cursor top={top} left={left} pressed={pressed}/>
                        <div>Dead zone: {DEAD_ZONE}</div>
                        <div>X: {gamepad.axes[0]}</div>
                        <div>Y: {gamepad.axes[1]}</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex flex-wrap">
                        {gamepad.buttons.map((button, index) => (
                            <div key={index} className="my-1 mx-1">
                                <Button variant={button.pressed ? 'danger' : 'primary'}
                                        style={{width: 50}}>{index}</Button>
                            </div>
                        ))}
                    </div>
                    <div className="mt-5">
                        <div className="mt-3" style={{fontSize: 25}}>
                            <Serial/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : <div>Gamepad disconnected</div>;
}

export default Gamepad;
