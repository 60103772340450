import './App.css';
import {GamepadsProvider} from 'react-gamepads';
import GamepadContainerWithoutContext from "./component/GamepadContainerWithoutContext";
import GamepadContainerWithContext from "./component/GamepadContainerWithContext";

function App() {
    return (
        <div className="App mt-5">
            <GamepadsProvider>
                <h2>Custom</h2>
                <GamepadContainerWithoutContext/>
                <h2 className="mt-5">React gamepads</h2>
                <GamepadContainerWithContext/>
            </GamepadsProvider>
        </div>
    );
}

export default App;
