import React from "react";

const Cursor = ({ top, left, pressed }) => {
    return (
        <div
            style={{
                width: "50px",
                height: "50px",
                backgroundColor: pressed ? "red" : "#0d6efd",
                position: "absolute",
                borderRadius: "50%",
                top,
                left
            }}
        />
    );
};

export default Cursor;
