import React, {useState} from 'react';
import {Button, FormCheck} from "react-bootstrap";

const MATRIX_SIZE = 8;

const Serial = () => {
    const [port, setPort] = useState()
    const [isConnected, setIsConnected] = useState(false);
    const matrix = Array(MATRIX_SIZE).fill(Array(MATRIX_SIZE).fill(0));

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const onCheck = (checked, x, y) => {
        if (checked) {
            ledOn(x, y);
        } else {
            ledOff(x, y);
        }
    };

    const ledOn = (x, y) => {
        if (!port) {
            return;
        }

        const writer = port.writable.getWriter();
        const encoder = new TextEncoder();
        writer.write(encoder.encode(`\x024603${x}${y}\x03`));
        writer.releaseLock();
    }

    const ledOff = (x, y) => {
        if (!port) {
            return;
        }

        const writer = port.writable.getWriter();
        const encoder = new TextEncoder();
        writer.write(encoder.encode(`\x024604${x}${y}\x03`));
        writer.releaseLock();
    }

    const allLedsOff = async () => {
        if (!port) {
            return;
        }

        const encoder = new TextEncoder();
        const writer = port.writable.getWriter();
        for (let y = 1; y <= MATRIX_SIZE; y++) {
            for (let x = 1; x <= MATRIX_SIZE; x++) {
                await writer.write(encoder.encode(`\x024604${x}${y}\x03`));
                await sleep(1);
            }
        }

        writer.releaseLock();
    };

    const allLedsOn = async () => {
        if (!port) {
            return;
        }

        const encoder = new TextEncoder();
        const writer = port.writable.getWriter();
        for (let y = 1; y <= MATRIX_SIZE; y++) {
            for (let x = 1; x <= MATRIX_SIZE; x++) {
                await writer.write(encoder.encode(`\x024603${x}${y}\x03`));
                await sleep(1);
            }
        }

        writer.releaseLock();
    };

    const connect = async () => {
        if (isConnected) {
            return;
        }

        let port = await navigator.serial.requestPort();
        await port.open({baudRate: 9600});
        setPort(port);
        setIsConnected(true);
    };

    const onConnect = async () => {
        await connect();
    };

    return (
        <>
            <div>
                <Button disabled={!('serial' in navigator)} onClick={onConnect}>Connect to serial</Button>
            </div>
            <div className="mt-3 d-flex justify-content-center ">
                <div className="d-flex flex-column-reverse">
                    {matrix.map((row, y) => {
                        return <div key={y} className="d-flex">
                            {row.map((item, x) => {
                                return <FormCheck
                                    disabled={!isConnected}
                                    onChange={(e) => onCheck(e.target.checked, x + 1, y + 1)}
                                    key={x}
                                    className="mx-1"/>
                            })}
                        </div>;
                    })}
                </div>
            </div>
            <div>
                <Button disabled={!isConnected} onClick={allLedsOff} className="mx-1">All off</Button>
                <Button disabled={!isConnected} onClick={allLedsOn} className="mx-1">All on</Button>
            </div>
        </>
    );
}

export default Serial;
